import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, withRouter, browserHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { APIService } from '../../../Config/actions/apiAction';
import { TokenService } from '../../../Config/actions/tokenAction';
import swal from 'sweetalert';

function FooterComponent(props) {

  const [email, setEmail] = useState('');
  const [emailError, setError] = useState('');
  const goTo = (e, url) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(url);
  }

  const navigate = (e, url) => {
    e.stopPropagation();
    e.preventDefault();
    // sessionStorage.setItem('homeScroll', window.pageYOffset);
    props.history.push(url);
  }
  const replace = (url) => {
    window.open(url);
}
  const subscribe = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (email && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email))) {
      setError('');
      let fd = new FormData();
      fd.append('email', email);
      props.dispatch(APIService('POST', '/save/newsletter', fd)).then((response) => {
        if (response && response.data.status === 'success') {
          setEmail('');
          showToast('success', response.data.message);
        }
        else if (response.data.auth_failed === '1') {
          props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
            showToast('error', 'Invalid login. Please try again');
            props.logoutUser();
          })
        }
        else {
          setEmail('')
          showToast('error', response.data.message);
        }
      }).catch((e) => {
        // console.log(e)
      })
    }
    else {
      let error = email ? (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) ? '' : 'Enter a valid email address' : 'Email address is required';
      setError(error);
    }
  }

  const showToast = (type, message) => {
    swal({
      title: message,
      text: ' ',
      icon: type,
      timer: 2000,
      buttons: false,
    })
  }
  return (
    <Fragment>
      <footer className="page-footer font-small special-color-dark">
        {/* <!-- Footer Elements --> */}
        <div className="container">
          {/* <!--Grid row--> */}
          <div className="row">

            {/* <!--Grid column--> */}
            <div className="col-md-5 col-xs-12 mb-0 mb-md-2">
              <h4 className="text-uppercase white_text">Stay Updated</h4>
              <p className="white_text mb-3 mini-text">Subscribe to our mailing list to get the latest updates <br />on our tours and fun facts.</p>
              {/* <!-- Form --> */}
              <form className="input-group" onSubmit={subscribe}>
                <input type="text" className="form-control form-control-sm" placeholder="Enter Your Email Address" value={email} onChange={(e) => setEmail(e.currentTarget.value)}
                  aria-label="Your email" aria-describedby="basic-addon2" />

                <div className="input-group-append">
                  <button className="btn btn-sm btn-outline-white subscribe_btn my-0" type="submit" style={{ outline: 'none' }}>Subscribe</button>
                </div>
              </form>
              <span className="white_text mt-2">{emailError}</span>
            </div>
            {/* <!--Grid column--> */}
            <div className="col-md-3 mb-2"></div>
            {/* <!--Grid column--> */}
            <div className="col-md-4 mb-2">
              <h4 className="text-uppercase white_text after_effect">Get in touch</h4>
              <div className="mb_30"></div>
              <p>HopOn India</p>
              <p><a href="mailto:contact@newhoiwebsite.museums22.com"><FontAwesomeIcon icon={['fa', 'envelope']} className="mr-3" />contact@newhoiwebsite.museums22.com</a></p>
              <p><a href="tel:919620534431"><FontAwesomeIcon icon={['fa', 'phone']} className="mr-3 call-contact" />+91 962 0534431</a></p>
              <p><a href="https://api.whatsapp.com/send?phone=917906681236"><FontAwesomeIcon icon={['fab', 'whatsapp']} className="mr-3" />+91 790 6681236</a></p>
              <ul className="footer_social_links">
                <li><a href="https://www.linkedin.com/in/hoponindia/" target="_blank" onClick={(e) => goTo(e, "https://www.linkedin.com/in/hoponindia/")} className="orange_text"><FontAwesomeIcon icon={['fab', 'linkedin']} /></a></li>
                <li><a href="https://www.facebook.com/hoponindia" target="_blank" onClick={(e) => goTo(e, "https://www.facebook.com/hoponindia")} className="orange_text"><FontAwesomeIcon icon={['fab', 'facebook']} /></a></li>
                <li><a href="https://twitter.com/hoponindia" target="_blank" onClick={(e) => goTo(e, "https://twitter.com/hoponindia")} className="orange_text"><FontAwesomeIcon icon={['fab', 'twitter']} /></a></li>
                <li><a href="https://www.instagram.com/hoponindia/" target="_blank" onClick={(e) => goTo(e, "https://www.instagram.com/hoponindia/")} className="orange_text"><FontAwesomeIcon icon={['fab', 'instagram']} /></a></li>
              </ul>
            </div>
            {/* <!--Grid column--> */}


          </div>
          {/* <!--Grid row--> */}
        </div>
        {/*  Footer Elements */}


      </footer>
      <section className="copyright_text">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6 text-left set_mobile_center">
              <ul className="footer_link">
                {/* <li><a onClick={(e) => navigate(e, '/login')} style={{ cursor: 'pointer' }} className="white_text">Login</a></li> */}
                <li><a onClick={(e) => navigate(e, '/cities-and-tours')} style={{ cursor: 'pointer' }} className="white_text">Tours</a></li>
                <li><a onClick={(e) => navigate(e, '/')} style={{ cursor: 'pointer' }} className="white_text">Partner</a></li>
                <li><a onClick={() => replace("https://newhoiwebsite.museums22.com/blog/")} style={{ cursor: 'pointer' }} className="white_text">Blog</a></li>
                <li><a onClick={(e) => navigate(e, '/about-us')} style={{ cursor: 'pointer' }} className="white_text">About Us</a></li>
                {
                  props.location.pathname.includes("cities-and-tours") || props.location.pathname.includes("buy-tour") ? (
                    <li><a onClick={(e) => navigate(e, '/faqs')} style={{ cursor: 'pointer' }} className="white_text">FAQs</a></li>
                  ) : null
                }
              </ul>
            </div>
            <div className="col-xs-12 col-md-6 text-right set_mobile_center">
              <p className="mini-text white_text">© {new Date().getFullYear()} HopOn India by Desiwalks Tours Pvt. Ltd.</p>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Footer --> */}
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.UserReducer,
    apiData: state.ApiReducer
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: () => {
      dispatch({
        type: 'loginUser'
      });
    },
    logoutUser: () => {
      dispatch({
        type: 'logoutUser'
      });
    },
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FooterComponent));
