import axios from 'axios';
import { connect } from 'react-redux';
import * as apiActions from '../reducers/apiReducer';
import * as userActions from '../reducers/userReducer';

export const APIService = (type, link, data) => {
    return async (dispatch) => {
        if (link === 'tourlocation/buytour' || link === 'tourlocation/buypackage') {
            let user = JSON.parse(localStorage.getItem('hoi_user')) || '';
            let config = {
                headers: {
                    "token": user.token,
                    "user_id": user.user_id,
                    "device": "web",
                    "tenant_id": 1
                }
            }
            dispatch(apiActions.fetchDataRequest());
            let response;
            try {
                response = await axios.post(`https://newhoiwebsite.museums22.com/multitenant/api/web/v5/${link}`, data, config);
                dispatch(apiActions.fetchDataComplete());
                return response;
            }
            catch (e) {
                dispatch(apiActions.fetchDataComplete());
                return e;
            }
        }
        else {
            let urlConst = 'https://partners.museums22.com/api/web';
            let config = {};
            let user = JSON.parse(localStorage.getItem('hoi_user')) || '';
            let apiUrl = '';

            apiUrl = urlConst + link;
            const header = new Headers();

            if (user && (link !== '/tourlocation/purchasetourlist') && (link !== '/user/updateprofile') && (link !== '/user/like-unlike') && (link !== '/promo/check')) {
                config = {
                    headers: {
                        "Authorization": user.token
                    }
                }
            }
            else if (link.includes('/tourlocation/purchasetourlist') || link.includes('/user/updateprofile') || (link === '/user/like-unlike') || (link === '/promo/check')) {
                config = {
                    headers: {
                        "Authorization": user.token,
                        "user_id": user.user_id
                    }
                }
            }
            else {
                config = {
                    headers: {}
                }
            }
            if (type === 'GET') {
                dispatch(apiActions.fetchDataRequest());
                let response;
                try {
                    response = await axios.get(apiUrl, config);
                    dispatch(apiActions.fetchDataComplete());
                    return response;
                }
                catch (e) {
                    dispatch(apiActions.fetchDataComplete());
                    return e;
                }
            }
            else if (type === 'POST') {
                dispatch(apiActions.fetchDataRequest());
                let response;
                try {
                    response = await axios.post(apiUrl, data, config);
                    dispatch(apiActions.fetchDataComplete());
                    return response;
                }
                catch (e) {
                    dispatch(apiActions.fetchDataComplete());
                    return e;
                }
            }
            else if (type === 'PUT') {
                dispatch(apiActions.fetchDataRequest());
                let response;
                try {
                    response = await axios.put(apiUrl, data, config);
                    dispatch(apiActions.fetchDataComplete())
                    return response;
                }
                catch (e) {
                    dispatch(apiActions.fetchDataComplete());
                    return e;
                }
            }
            else if (type === 'DELETE') {
                dispatch(apiActions.fetchDataRequest());
                let response;
                try {
                    response = await axios.delete(apiUrl, config);
                    dispatch(apiActions.fetchDataComplete())
                    return response;
                }
                catch (e) {
                    dispatch(apiActions.fetchDataComplete());
                    return e;
                }
            }
        }
    }
}
