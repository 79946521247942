import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../Common/components/header';
import FooterComponent from '../../../Common/components/footer';
import OwlCarousel, { render } from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { APIService } from '../../../../Config/actions/apiAction';
import { TokenService } from '../../../../Config/actions/tokenAction';
import { sha512 } from 'js-sha512';
import BuyNow from '../../../Common/components/buynow';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
import axios from 'axios';
import MetaDecorator from '../../../Common/components/metaDecorator';

let bolt;
class PurchasePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentTab: 1,
            tour: false,
            email: '',
            password: '',
            name: '',
            confirmPassword: '',
            phone: '',
            error: {
                email: '',
                phone: '',
                password: '',
                confirmPassword: '',
                name: ''
            },
            data: '',
            price: '',
            promoCheckbox: false,
            promoCode: '',
            promocodeError: '',
            isPromoApplied: false,
            radioValue: 3,
            formData: {},
            pg: 'CC',
            paymentWindow: '',
            years: '',
            cardDetails: {
                name: '',
                card: '',
                month: '',
                year: '',
                cvv: ''
            },
            meta_info: {}
        }
    }

    // Swal alerts
    showToast = (type, message) => {
        swal({
            title: message,
            text: ' ',
            icon: type,
            timer: 2000,
            buttons: false,
        })
    }

    // Debit card input handlers
    cardHandler = (key, value) => {
        if (key === 'card' || key === 'cvv') {
            this.setState({
                ...this.state,
                cardDetails: {
                    ...this.state.cardDetails,
                    [key]: (key === 'card') ? value.length < 16 ? value : value.slice(0, 16) : value.length < 3 ? value : value.slice(0, 3)
                }
            })
        }
        else if (key === 'name' || key === 'month' || key === 'year') {
            this.setState({
                ...this.state,
                cardDetails: {
                    ...this.state.cardDetails,
                    [key]: value
                }
            })
        }
    }

    // Handling the submission of form tab wise
    submitHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (this.state.currentTab === 1) {
            if (this.state.email && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email))) {
                this.setState({
                    ...this.state,
                    error: {
                        email: ''
                    }
                }, () => {
                    let fd = new FormData();
                    fd.append('email', this.state.email);
                    this.props.dispatch(APIService('POST', '/user/check-account-exists', fd)).then((data) => {
                        if (data && data.data.status === 'success') {
                            this.setState({
                                currentTab: 2
                            })
                        }
                        else if (data.data.auth_failed === '1') {
                            this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                                this.showToast('error', 'Invalid login. Please try again');
                                this.props.logoutUser();
                            })
                        }
                        else {
                            this.setState({
                                currentTab: 3
                            })
                        }
                    }).catch((e) => {
                        // console.log(e)
                    })
                })
            }
            else {
                this.setState({
                    ...this.state,
                    error: {
                        email: this.state.email ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email) ? '' : 'Enter a valid email address' : 'Email address is required'
                    }
                })
            }
        }

        // Login Tab handling
        else if (this.state.currentTab === 2) {
            if ((this.state.email && this.state.password) && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email))) {
                this.setState({
                    ...this.state,
                    error: {
                        email: '',
                        password: ''
                    }
                }, () => {
                    let fd = new FormData();
                    fd.append('email', this.state.email);
                    fd.append('password', this.state.password);
                    this.props.dispatch(APIService('POST', '/login/makelogin', fd)).then((data) => {
                        if (data && data.data.status === 'success') {
                            this.props.dispatch(TokenService('setToken', { key: 'hoi_user', value: data.data.data })).then(() => {
                                this.props.loginUser();
                                this.showToast('success', 'Login successful');
                                this.createHash();
                                this.setState({
                                    ...this.state,
                                    email: '',
                                    password: '',
                                    currentTab: 1
                                })
                            })
                        }
                        else if (data.data.auth_failed === '1') {
                            this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                                this.showToast('error', 'Invalid login. Please try again');
                                this.props.logoutUser();
                            })
                        }
                        else {
                            // console.log('error', data.data)
                        }
                    }).catch((e) => {
                        // console.log(e)
                    })
                })
            }
            else {
                this.setState({
                    ...this.state,
                    error: {
                        email: this.state.email ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email) ? '' : 'Enter a valid email address' : 'Email address is required',
                        password: this.state.password ? '' : 'Password is required'
                    }
                })
            }
        }

        // Regsiter form handling
        else if (this.state.currentTab === 3) {
            if ((this.state.email && this.state.name && this.state.password && this.state.phone && this.state.confirmPassword) && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email)) && (this.state.password === this.state.confirmPassword) && (this.state.phone.length >= 10)) {
                this.setState({
                    ...this.state,
                    error: {
                        email: '',
                        phone: '',
                        password: '',
                        confirmPassword: '',
                        name: ''
                    }
                }, () => {
                    let fd = new FormData();
                    fd.append('email', this.state.email);
                    fd.append('phone', this.state.phone);
                    fd.append('name', this.state.name);
                    fd.append('language_id', '1');
                    fd.append('password', this.state.password);
                    fd.append('verify_password', this.state.confirmPassword);
                    this.props.dispatch(APIService('POST', '/user/register', fd)).then((data) => {
                        if (data && data.data.status === 'success') {
                            this.props.dispatch(TokenService('setToken', { key: 'hoi_user', value: data.data.data })).then(() => {
                                this.props.loginUser();
                                this.showToast('success', 'Registered successfully');
                                this.createHash();
                                this.setState({
                                    ...this.state,
                                    email: '',
                                    password: '',
                                    phone: '',
                                    name: '',
                                    confirmPassword: '',
                                    currentTab: 1
                                })
                            })
                        }
                        else if (data.data.auth_failed === '1') {
                            this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                                this.showToast('error', 'Invalid login. Please try again');
                                this.props.logoutUser();
                            })
                        }
                        else {
                            // console.log('error', data.data)
                        }
                    }).catch((e) => {
                        // console.log(e)
                    })
                })
            }
            else {
                this.setState({
                    ...this.state,
                    error: {
                        email: this.state.email ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.state.email) ? '' : 'Enter a valid email address' : 'Email address is required',
                        password: this.state.password ? '' : 'Password is required',
                        phone: this.state.phone ? this.state.phone.length >= 10 ? '' : 'Phone number should be minimum 10 digits' : 'Phone number is required',
                        confirmPassword: this.state.confirmPassword ? (this.state.password === this.state.confirmPassword) ? '' : "Confirm password doesn't match" : 'Confirm password is required',
                        name: this.state.name ? '' : 'Name is required'
                    }
                })
            }
        }
    }

    componentDidMount() {

        let noOfYears = [];
        let currentYear = new Date().getFullYear();
        for (let i = 0; i < 20; i++) {
            noOfYears.push(currentYear + i);
        }

        let promoCheck = this.props.location.search && this.props.location.search.split('=')[1];

        this.setState({
            tour: (this.props.match.params && this.props.match.params.tourId) ? true : false,
            years: noOfYears,
            promoCheckbox: promoCheck ? true : false,
            promoCode: promoCheck
        }, () => {
            this.getData();
            // console.log(this.state.cardDetails)
        })
    }

    // Get Tour Data
    getData = () => {
        let url = this.state.tour ? '/walk/tourdetails' : '/package/packagedetails';
        let fd = new FormData();
        if (this.state.tour) {
            fd.append('tour_id', this.props.match.params.tourId)
        }
        else {
            fd.append('package_id', this.props.match.params.packageId)
        }
        this.props.dispatch(APIService('POST', url, fd)).then((data) => {
            if (data && data.data.status === 'success') {
                if (data.data.data.location_info.is_bought === '1') {
                    this.props.history.replace(this.state.tour ? `/tour-detail/${data.data.data.location_info.tour_id}` : `/package-detail/${data.data.data.location_info.package_id}`);
                }
                else {
                    let key = this.state.tour ? 'tour_price' : 'package_price';
                    this.setState({
                        data: data.data.data,
                        price: data.data.data.location_info[key],
                        meta_info: (data.data.data.meta_info != null) ? data.data.data.meta_info : { title: 'HopOn India', meta_description: 'HopOn India', meta_keyword: 'HopOn India' },
                    }, () => {
                        this.createHash();
                    })
                }
            }
            else if (data.data.auth_failed === '1') {
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showToast('error', 'Invalid login. Please try again');
                    this.props.logoutUser();
                })
            }
            else {
                // console.log('error')
            }
        }).catch((e) => {
            // console.log(e)
        })
    }

    inputHandler = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    // Create Hash token for payment
    createHash = () => {
        this.props.dispatch(TokenService('getToken', 'hoi_user')).then((data) => {
            if (data) {
                // console.log(data, this.state.data)
                let txn = this.generateTxnid();
                var pd = {
                    key: 'yWgqef',
                    txnid: txn,
                    amount: this.state.price,
                    // amount: '1',
                    firstname: data.name,
                    email: data.email,
                    phone: data.phone,
                    productinfo: this.state.tour ? this.state.data.location_info.tour_id : this.state.data.location_info.package_id,
                    surl: this.state.tour ? `https://www.newhoiwebsite.museums22.com/web/backend/callback/success` : `https://www.newhoiwebsite.museums22.com/web/backend/callback/success-package`,
                    furl: this.state.tour ? `https://www.newhoiwebsite.museums22.com/web/backend/callback/failure` : `https://www.newhoiwebsite.museums22.com/web/backend/callback/failure-package`,
                    hash: '',
                    salt: '5v1uf1ZE',
                    udf1: data.user_id,
                    udf2: 'web',
                    udf3: this.state.isPromoApplied ? this.state.promoCode : '-'
                };

                let hashValue = sha512(pd.key + '|' + pd.txnid + '|' + pd.amount + '|' + pd.productinfo + '|' + data.name + '|' + data.email + '|' + pd.udf1 + '|' + pd.udf2 + '|' + pd.udf3 + '||||||||' + pd.salt);
                pd.hash = hashValue;
                this.setState({
                    ...this.state,
                    formData: pd
                }, () => {
                    // console.log(this.state.formData)
                });
            }
        })
    }

    // Pay now modal popup
    payNow = (e) => {
        // e.preventDefault();
        // e.stopPropagation();
        let paymentWindow = window.open('about:blank', 'payment_popup', 'width=900,height=500');
        this.setState({
            paymentWindow: paymentWindow
        }, () => {
            this.trackWindow();
        })
    }

    trackWindow = () => {
        if (this.state.paymentWindow) {
            setTimeout(() => {
                // console.log(this.state.paymentWindow.closed);
                if (this.state.paymentWindow.closed) {
                    this.setState({
                        trackWindow: ''
                    }, () => {
                        this.getData();
                    })
                }
                else {
                    this.trackWindow()
                }
            }, 1000)
        }
    }

    // Payment method changed
    promoCodeChange = () => {
        this.setState(prevstate => {
            return {
                promoCheckbox: !prevstate.promoCheckbox
            }
        }, () => {
            if (this.state.promoCheckbox) {
                this.setState({
                    ...this.state,
                    radioValue: 3
                })
            }
            else {
                this.setState({
                    ...this.state,
                    promoCode: ''
                })
            }
        })
    }

    // Direct purchase tour functionality when tour price is 0
    purchaseTour = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let key = this.state.tour ? 'tour_id' : 'package_id';
        let url = this.state.tour ? 'tourlocation/buytour' : 'tourlocation/buypackage';
        let txn = this.state.formData.txnid;
        let fd = new FormData();
        fd.append('transaction_id', txn);
        fd.append('payment_status', 'success');
        fd.append(key, this.state.data.location_info[key]);
        fd.append('pmc_code', this.state.promoCode);

        this.props.dispatch(APIService('POST', url, fd)).then((data) => {
            if (data.data.status === 'success') {
                this.props.history.replace(this.state.tour ? `/tour/success/${txn}` : `/package/success/${txn}`);
            }
            else if (data.data.auth_failed === '1') {
                this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                    this.showToast('error', 'Invalid login. Please try again');
                    this.removePromocode();
                    this.props.logoutUser();
                })
            }
            else {
                // console.log('error')
            }
        })

    }

    // Apply promocode functionality
    applyPromocode = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            ...this.state,
            promocodeError: ''
        }, () => {
            let key = this.state.tour ? 'tour_id' : 'package_id';

            let fd = new FormData();
            fd.append('promo_code', this.state.promoCode);
            fd.append(key, this.state.data.location_info[key]);
            this.props.dispatch(APIService('POST', '/promo/check', fd)).then((data) => {
                if (data.data.status === 'success') {
                    this.setState({
                        ...this.state,
                        price: data.data.data.final_amount,
                        isPromoApplied: true
                    }, () => {
                        this.createHash();
                    })
                }
                else if (data.data.auth_failed === '1') {
                    this.props.dispatch(TokenService('removeToken', 'hoi_user')).then(() => {
                        this.showToast('error', 'Invalid login. Please try again');
                        this.props.logoutUser();
                    })
                }
                else {
                    this.setState({
                        ...this.state,
                        promocodeError: data.data.message
                    })
                }
            }).catch(err => {
                // console.log(err);
            })
        })
    }

    // Remove promocode functionality
    removePromocode = () => {
        let key = this.state.tour ? 'tour_price' : 'package_price';
        this.setState({
            ...this.state,
            promoCheckbox: false,
            isPromoApplied: false,
            promoCode: '',
            price: this.state.data.location_info[key]
        });
        this.createHash();
    }

    // Generate transcation id
    generateTxnid = () => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 15; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    // Payment method change input handler
    radioChange = (value) => {
        this.setState({
            radioValue: value,
            pg: value === 1 ? 'PAYPAL' : 'CC',
            promoCheckbox: false,
            promoCode: ''
        }, () => {
            // console.log(this.state.pg)
        })
    }

    paymentHandler = (key, value) => {
        let formData = this.state.formData;
        formData[key] = value;
        this.setState({
            formData
        }, () => {
            // console.log(this.state.formData)
        })
    }

    render() {
        return (
            <Fragment>
                <MetaDecorator meta_info={this.state.meta_info} />
                {/* Html goes here */}
                <HeaderComponent />
                {/* <BuyNow /> */}
                <section className="page_section mt-3 mt-sm-0" >
                    <BuyNow />
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-5 col-md-6">
                                <button className="btn  back_option_page" onClick={() => this.props.history.goBack()}> <FontAwesomeIcon icon="angle-left" /> Back</button>
                                <h1 className=""><b>Complete Your Purchase</b></h1>
                                <p>The tour comes with a validity of 90 days after your purchase. You may take the tour multiple times if you like to.</p>

                                {
                                    (this.props.user && !this.props.user.isAuthenticated) ? (
                                        <Fragment>
                                            {
                                                (this.state.currentTab === 1) ? (
                                                    <Fragment>
                                                        {/* Email id enter section :: start */}
                                                        <div className="purchase_box bg_light_orange">
                                                            <h1 className="">Enter Your Details</h1>
                                                            <form className="purchase_form" onSubmit={this.submitHandler}>
                                                                <div className="input-group mb-4">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                                                    </div>
                                                                    <input type="text" className="form-control" value={this.state.email} onChange={(e) => this.inputHandler('email', e.currentTarget.value)} placeholder="Enter Your Email Address" />
                                                                </div>
                                                                {this.state.error.email ? (
                                                                    <p style={{ color: 'red', fontSize: '13px', marginTop: '-17px', marginLeft: '5px' }}>{this.state.error.email}*</p>
                                                                ) : null}
                                                                <div className="form-group">
                                                                    <input type="submit" value="Verify" className="form-control btn orange_btn btn-sm text-uppercase" />
                                                                </div>
                                                            </form>
                                                        </div>
                                                        {/* Email id enter section :: end */}
                                                    </Fragment>
                                                ) : (this.state.currentTab === 2) ? (
                                                    <Fragment>
                                                        {/* login section :: start */}
                                                        <div className="purchase_box bg_light_orange">
                                                            <h1 className="">Enter Your Details</h1>
                                                            <p>Looks like you already have a registered account with HopOn India! Enter your Password</p>
                                                            <form className="purchase_form" onSubmit={this.submitHandler}>
                                                                <div className="input-group mb-4">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                                                    </div>
                                                                    <input type="text" className="form-control" value={this.state.email} onChange={(e) => this.inputHandler('email', e.currentTarget.value)} placeholder="Enter Your Email Address" />
                                                                </div>
                                                                {this.state.error.email ? (
                                                                    <p style={{ color: 'red', fontSize: '13px', marginTop: '-17px', marginLeft: '5px' }}>{this.state.error.email}*</p>
                                                                ) : null}
                                                                <div className="input-group mb-4">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon="lock" /></span>
                                                                    </div>
                                                                    <input type="password" className="form-control" value={this.state.password} onChange={(e) => this.inputHandler('password', e.currentTarget.value)} placeholder="Enter Your Password" />
                                                                </div>
                                                                {this.state.error.password ? (
                                                                    <p style={{ color: 'red', fontSize: '13px', marginTop: '-17px', marginLeft: '5px' }}>{this.state.error.password}*</p>
                                                                ) : null}
                                                                <div className="form-group">
                                                                    <input type="submit" value="Log In" className="form-control btn orange_btn btn-sm text-uppercase" />
                                                                </div>
                                                            </form>
                                                        </div>
                                                        {/* Login section :: end */}
                                                    </Fragment>
                                                ) : (this.state.currentTab === 3) ? (
                                                    <Fragment>
                                                        {/* detail enter section :: start */}
                                                        <div className="purchase_box bg_light_orange">
                                                            <h1 className="">Enter Your Details</h1>
                                                            <form className="purchase_form" onSubmit={this.submitHandler}>
                                                                <div className="input-group mb-4">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                                                    </div>
                                                                    <input type="text" className="form-control" value={this.state.name} onChange={(e) => this.inputHandler('name', e.currentTarget.value)} placeholder="Enter Your Name" />
                                                                </div>
                                                                {this.state.error.name ? (
                                                                    <p style={{ color: 'red', fontSize: '13px', marginTop: '-17px', marginLeft: '5px' }}>{this.state.error.name}*</p>
                                                                ) : null}
                                                                <div className="input-group mb-4">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                                                    </div>
                                                                    <input type="text" className="form-control" value={this.state.email} onChange={(e) => this.inputHandler('email', e.currentTarget.value)} placeholder="Enter Your Email Address" />
                                                                </div>
                                                                {this.state.error.email ? (
                                                                    <p style={{ color: 'red', fontSize: '13px', marginTop: '-17px', marginLeft: '5px' }}>{this.state.error.email}*</p>
                                                                ) : null}
                                                                <div className="input-group mb-4">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon="mobile" /></span>
                                                                    </div>
                                                                    <input type="tel" className="form-control" value={this.state.phone} onChange={(e) => this.inputHandler('phone', e.currentTarget.value)} placeholder="Phone Number" />
                                                                </div>
                                                                {this.state.error.phone ? (
                                                                    <p style={{ color: 'red', fontSize: '13px', marginTop: '-17px', marginLeft: '5px' }}>{this.state.error.phone}*</p>
                                                                ) : null}
                                                                <div className="input-group mb-4">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon="lock" /></span>
                                                                    </div>
                                                                    <input type="password" className="form-control" value={this.state.password} onChange={(e) => this.inputHandler('password', e.currentTarget.value)} placeholder="Enter Your Password" />
                                                                </div>
                                                                {this.state.error.password ? (
                                                                    <p style={{ color: 'red', fontSize: '13px', marginTop: '-17px', marginLeft: '5px' }}>{this.state.error.password}*</p>
                                                                ) : null}
                                                                <div className="input-group mb-4">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text"><FontAwesomeIcon icon="lock" /></span>
                                                                    </div>
                                                                    <input type="password" className="form-control" value={this.state.confirmPassword} onChange={(e) => this.inputHandler('confirmPassword', e.currentTarget.value)} placeholder="Re-Enter Your Password" />
                                                                </div>
                                                                {this.state.error.confirmPassword ? (
                                                                    <p style={{ color: 'red', fontSize: '13px', marginTop: '-17px', marginLeft: '5px' }}>{this.state.error.confirmPassword}*</p>
                                                                ) : null}
                                                                <div className="form-group">
                                                                    <input type="submit" value="SIGN UP" className="form-control btn orange_btn btn-sm text-uppercase" />
                                                                </div>
                                                            </form>
                                                        </div>
                                                        {/* detail enter section :: end */}
                                                    </Fragment>
                                                ) : null
                                            }




                                        </Fragment>
                                    ) : null
                                }

                                {/* radio option selection :: start */}
                                <div className="purchase_box">
                                    <h3 style={{ marginBottom: '25px' }}>Pay <span className="price_text">₹{this.state.price && this.state.price}</span></h3>
                                    {
                                        this.state.isPromoApplied ? (
                                            <Fragment>
                                                <div style={{ width: '100%', border: '0px solid black', height: '48px' }}>
                                                    <div style={{
                                                        float: 'left', textAlign: 'center', border: '2px dotted #f47421', paddingTop: '9px',
                                                        paddingBottom: '9px', paddingLeft: '15px', paddingRight: '15px', borderRadius: '5px', color: '#f47421', fontWeight: 'bold', backgroundColor: '#FFDDC8'
                                                    }}>
                                                        {this.state.promoCode}
                                                    </div>
                                                    <div style={{
                                                        float: 'right', color: 'rgb(13, 130, 130)', fontWeight: 'bold', fontSize: '15px', paddingTop: '12px', paddingBottom: '12px',
                                                        paddingRight: '10px'
                                                    }}>
                                                        <span style={{ cursor: 'pointer' }} onClick={this.removePromocode}><u>REMOVE</u></span>
                                                    </div>
                                                </div>
                                                <p style={{ fontSize: '15px', marginTop: '5px' }}>
                                                    Promocode applied, complete your transaction
                                                </p>
                                                {
                                                    this.state.price === '0' ? (
                                                        <div className="form-group">
                                                            <input type="button" value="Buy Now" onClick={this.purchaseTour} style={{ lineHeight: '10px' }} className="form-control btn orange_btn btn-sm text-uppercase" />
                                                        </div>
                                                    ) : (
                                                        <Fragment>
                                                            <form action="https://secure.payu.in/_payment" name="payuform" method="POST">

                                                                <input type="hidden" name="key" id="key" readOnly value={this.state.formData.key || ''} />
                                                                <input type="hidden" name="amount" id="amount" readOnly value={this.state.formData.amount || ''} />
                                                                <input type="hidden" name="email" id="email" readOnly value={this.state.formData.email || ''} />
                                                                <input type="hidden" name="firstname" id="firstname" readOnly value={this.state.formData.firstname || ''} />

                                                                <input type="hidden" name="hash" readOnly id="hash" value={this.state.formData.hash || ''} />
                                                                <input type="hidden" name="name" readOnly id="name" value={this.state.formData.firstname || ''} />
                                                                <input type="hidden" name="phone" readOnly id="phone" value={this.state.formData.phone || ''} />
                                                                <input type="hidden" name="productinfo" readOnly id="productinfo" value={this.state.formData.productinfo || ''} />
                                                                <input type="hidden" name="salt" readOnly id="salt" value={this.state.formData.salt || ''} />

                                                                <input type="hidden" name="furl" readOnly id="furl" value={this.state.formData.furl || ''} />
                                                                <input type="hidden" name="surl" readOnly id="surl" value={this.state.formData.surl || ''} />
                                                                <input type="hidden" name="txnid" readOnly id="txnid" value={this.state.formData.txnid || ''} />

                                                                <input type="hidden" name="udf1" readOnly id="udf1" value={this.state.formData.udf1 || ''} />
                                                                <input type="hidden" name="udf2" readOnly id="udf2" value={this.state.formData.udf2 || ''} />
                                                                <input type="hidden" name="udf3" readOnly id="udf3" value={this.state.formData.udf3 || ''} />

                                                                <input type="hidden" name="pg" value={this.state.pg} />
                                                                <div className="form-group">
                                                                    <input type="submit" value="Buy Now" style={{ lineHeight: '10px' }} className="form-control btn orange_btn btn-sm text-uppercase" />
                                                                </div>

                                                            </form>
                                                        </Fragment>
                                                    )
                                                }
                                            </Fragment>
                                        ) : (
                                            <Fragment>

                                                <div className="form-group">
                                                    <div className="radio">
                                                        <input id="paypal_option" onChange={() => this.radioChange(1)} checked={this.state.radioValue === 1 ? true : false} name="radio" type="radio" />
                                                        <label htmlFor="paypal_option" className="radio-label">Pay Using International Credit Card Via Paypal</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="radio">
                                                        <input id="cc_option" name="radio" onChange={() => this.radioChange(2)} checked={this.state.radioValue === 2 ? true : false} type="radio" />
                                                        <label htmlFor="cc_option" className="radio-label">Pay Using Indian Credit Card</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="radio">
                                                        <input id="other_option" name="radio" onChange={() => this.radioChange(3)} checked={this.state.radioValue === 3 ? true : false} type="radio" />
                                                        <label htmlFor="other_option" className="radio-label">Other</label>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="radio" style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                        <Checkbox disabled={!this.props.user.isAuthenticated} checked={this.state.promoCheckbox} onChange={this.promoCodeChange} name="checkedA" /> Have a Promocode?
                                                    </div>
                                                </div>

                                                {
                                                    (this.state.radioValue === 2) ? (
                                                        <div className="cc_option_details">
                                                            <form>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="input-group mb-4">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text"><FontAwesomeIcon icon="credit-card" /></span>
                                                                            </div>
                                                                            <input type="number" className="form-control" name="card" maxLength="16" value={this.state.cardDetails.card} onChange={(e) => this.cardHandler('card', e.currentTarget.value)} placeholder="Card Number" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="input-group mb-4">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                                                            </div>
                                                                            <input type="text" className="form-control" value={this.state.cardDetails.name} onChange={(e) => this.cardHandler('name', e.currentTarget.value)} placeholder="Name" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-4">
                                                                        <div className="input-group mb-4">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text"><FontAwesomeIcon icon="calendar" /></span>
                                                                            </div>
                                                                            <select className="form-control" onChange={(e) => this.cardHandler('month', e.currentTarget.value)} value={this.state.cardDetails.month} placeholder="Ex. Month">
                                                                                <option value="">Month</option>
                                                                                <option value="01">01</option>
                                                                                <option value="02">02</option>
                                                                                <option value="03">03</option>
                                                                                <option value="04">04</option>
                                                                                <option value="05">05</option>
                                                                                <option value="06">06</option>
                                                                                <option value="07">07</option>
                                                                                <option value="08">08</option>
                                                                                <option value="09">09</option>
                                                                                <option value="10">10</option>
                                                                                <option value="11">11</option>
                                                                                <option value="12">12</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-4">
                                                                        <div className="input-group mb-4">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text"><FontAwesomeIcon icon="calendar" /></span>
                                                                            </div>
                                                                            <select className="form-control" onChange={(e) => this.cardHandler('year', e.currentTarget.value)} value={this.state.cardDetails.year} placeholder="Ex. Year">
                                                                                <option value="">Year</option>
                                                                                {
                                                                                    this.state.years && this.state.years.length ? (
                                                                                        <Fragment>
                                                                                            {
                                                                                                this.state.years.map((item, index) => {
                                                                                                    return (
                                                                                                        <option key={item} value={item}>{item}</option>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Fragment>
                                                                                    ) : null
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 col-md-4">
                                                                        <div className="input-group mb-4">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text"><FontAwesomeIcon icon="user" /></span>
                                                                            </div>
                                                                            <input type="number" name="cvv" className="form-control" value={this.state.cardDetails.cvv} onChange={(e) => this.cardHandler('cvv', e.currentTarget.value)} placeholder="CVV" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    this.state.promoCheckbox ? (
                                                        <div className="form-group">
                                                            <div className="radio">
                                                                <input id="other_option" name="promocode" value={this.state.promoCode} onChange={(e) => this.inputHandler('promoCode', e.currentTarget.value)} type="text" style={{ width: '80%', height: '40px', outline: 'none', border: '1px solid silver', borderRadius: '0px', paddingLeft: '10px' }} placeholder="Enter Promocode" />
                                                                <input type="button" value="Apply" disabled={this.state.promoCode && this.props.user.isAuthenticated ? false : true} onClick={this.applyPromocode} style={{ width: '20%', height: '40px', border: '0px solid silver', borderRadius: '0px', backgroundColor: '#FF8548', color: 'white' }} />
                                                            </div>
                                                            {
                                                                this.state.promocodeError ? (
                                                                    <span style={{ color: 'red', fontSize: '14px' }}>{this.state.promocodeError}</span>
                                                                ) : null
                                                            }
                                                        </div>
                                                    ) : (
                                                        <Fragment>
                                                            {
                                                                this.state.radioValue ? (
                                                                    // target="payment_popup" onSubmit={this.payNow}
                                                                    <form action="https://secure.payu.in/_payment" name="payuform" method="POST">

                                                                        <input type="hidden" name="key" id="key" readOnly value={this.state.formData.key || ''} />
                                                                        <input type="hidden" name="amount" id="amount" readOnly value={this.state.formData.amount || ''} />
                                                                        <input type="hidden" name="email" id="email" readOnly value={this.state.formData.email || ''} />
                                                                        <input type="hidden" name="firstname" id="firstname" readOnly value={this.state.formData.firstname || ''} />

                                                                        <input type="hidden" name="hash" readOnly id="hash" value={this.state.formData.hash || ''} />
                                                                        <input type="hidden" name="name" readOnly id="name" value={this.state.formData.firstname || ''} />
                                                                        <input type="hidden" name="phone" readOnly id="phone" value={this.state.formData.phone || ''} />
                                                                        <input type="hidden" name="productinfo" readOnly id="productinfo" value={this.state.formData.productinfo || ''} />
                                                                        <input type="hidden" name="salt" readOnly id="salt" value={this.state.formData.salt || ''} />

                                                                        <input type="hidden" name="furl" readOnly id="furl" value={this.state.formData.furl || ''} />
                                                                        <input type="hidden" name="surl" readOnly id="surl" value={this.state.formData.surl || ''} />
                                                                        <input type="hidden" name="txnid" readOnly id="txnid" value={this.state.formData.txnid || ''} />

                                                                        <input type="hidden" name="udf1" readOnly id="udf1" value={this.state.formData.udf1 || ''} />
                                                                        <input type="hidden" name="udf2" readOnly id="udf2" value={this.state.formData.udf2 || ''} />
                                                                        <input type="hidden" name="udf3" readOnly id="udf3" value={this.state.formData.udf3 || ''} />

                                                                        {
                                                                            this.state.radioValue === 2 ? (
                                                                                <Fragment>
                                                                                    <input type="hidden" name="ccname" readOnly id="buy_owner" value={this.state.cardDetails.name || ''} />
                                                                                    <input type="hidden" name="ccnum" readOnly id="buy_cardNumber" value={this.state.cardDetails.card || ''} />
                                                                                    <input type="hidden" name="ccexpmon" readOnly id="buy_sel_expmon" value={this.state.cardDetails.month || ''} />
                                                                                    <input type="hidden" name="ccexpyr" readOnly id="sel2" value={this.state.cardDetails.year || ''} />
                                                                                    <input type="hidden" name="ccvv" readOnly id="buy_cvv" value={this.state.cardDetails.cvv || ''} />
                                                                                </Fragment>
                                                                            ) : null
                                                                        }

                                                                        <input type="hidden" name="pg" value={this.state.pg} />
                                                                        <div className="form-group">
                                                                            <input type="submit" value="Pay" style={{ lineHeight: '10px' }} disabled={!this.props.user.isAuthenticated} className="form-control btn orange_btn btn-sm text-uppercase" />
                                                                        </div>

                                                                    </form>
                                                                ) : (
                                                                    <div className="form-group">
                                                                        <input type="button" value="Pay" disabled={!this.props.user.isAuthenticated} className="form-control btn orange_btn btn-sm text-uppercase" />
                                                                    </div>
                                                                )
                                                            }

                                                        </Fragment>
                                                    )
                                                }
                                            </Fragment>

                                        )
                                    }
                                    {/* </form> */}
                                </div>
                                {/* radio option selection :: end */}

                            </div>
                            <div className="col-12 col-lg-7 col-md-6 purchase_city_slider">
                                {
                                    (this.state.data && this.state.data.location_info.banner_img) ? (
                                        <OwlCarousel className="owl-theme" loop={false} margin={0} items={1} dots >
                                            <div className="item">
                                                <img src={this.state.data.location_info.banner_img} alt="Tour Image" />
                                                <h1 className="white_text" style={{ fontWeight: 'bold' }}>{this.state.data && this.state.data.location_info.tour_name}</h1>
                                            </div>
                                        </OwlCarousel>
                                    ) : null
                                }

                            </div>
                        </div>
                    </div>
                </section>
                <FooterComponent />

            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer,
        apiData: state.ApiReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loginUser: () => {
            dispatch({
                type: 'loginUser'
            });
        },
        logoutUser: () => {
            dispatch({
                type: 'logoutUser'
            });
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasePage);
