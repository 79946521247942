import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderComponent from '../../../Common/components/header';
import FooterComponent from '../../../Common/components/footer';
import avatar1 from '../../../../images/city_detail_banner.png';
import MetaDecorator from '../../../Common/components/metaDecorator';

export default class fads extends Component {

    componentDidMount() {
    }

    render() {
        return (
            <Fragment>
                <MetaDecorator meta_info={{ title: 'FAQS', meta_description: 'FAQS - HopOn India', meta_keyword: 'FAQS - HopOn India' }} />
                {/* Html goes here */}
                <HeaderComponent />
                {/* banner section starts here */}
                {/* <div className="banner_section inner_banner faq_banner"> 
                    <img src={avatar1} alt="Banner image" />
                    <div className="shadow_div_set"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center banner_title">
                                <h1 className=" white_text">FAQS</h1>
                            </div>
                        </div>
                    </div>
                </div>  */}
                {/* banner section ends here */}

                {/* faqs section starts here */}
                <section className="page_section faq_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <p><b>Question: What is the HopOn India app all about?</b></p>
                                <p>Answer: The App is like a tour guide that provides self-guided tours of places listed on the app. These include monuments, museums, palaces, forts, streets, and themes like culture, Indian cinema, Buddhism, sports and food among others</p>

                                <p><b>Question: How does the HopOn India app enhance my travel experience?</b></p>
                                <p>Answer: HopOn India tours are curated by local experts and capture their take on the place/ theme and its well and lesser-known stories. They talk about their lived experiences so that you can get an insider’s perspective</p>

                                <p><b>Question: How does one use the app to buy or activate a tour/ package?</b></p>
                                <p>Answer: You have already downloaded the app on your phone. Please register on the app (allow location and media storage access and also you may receive an OTP authentication request). Choose the city and tour you want to listen to. You can listen to the introduction before you decide to start the tour. You may pay using the in-app payment or feed an available promo code. Tap the price button to activate the tour or package</p>

                                <p><b>Question: Do I expect a pick up/ to meet someone at the starting location of the tour?</b></p>
                                <p>Answer: No, please do not expect a pick up/ to meet anyone at the starting point of the tour. This is a self-guided tour that you may take on your own using your phone and HopOn India App</p>

                                <p><b>Question: Do I need to carry anything/ prepare myself for the tour?</b></p>
                                <p>Answer: A fully charged smartphone, the pre-downloaded tour if you wish to go offline or expect low signals at the location, a set of ear/ headphones are advised. Please download the HopOn India App and listen to the introduction of the tour you desire to listen to for details of what you may carry/ prepare for</p>

                                <p><b>Question: Can I take the tour without an internet connection?</b></p>
                                <p>Answer: You can take the tours offline/ in airplane mode or without the internet if you have pre-downloaded the tour on your phone. The download button appears below the start tour button</p>

                                <p><b>Question: What are the “Navigation” mode and “Virtual” mode?</b></p>
                                <p>Answer: Use the 'Navigation' mode for the best experience when at the location and follow our recommended path. Use the 'Virtual' mode if you want to listen to the audio tour from a remote location</p>

                                <p><b>Question: Can I take the tour from a remote location or do I have to be at the location?</b></p>
                                <p>Answer: You can listen to the audio tour from anywhere in the world using the “Virtual” mode</p>

                                <p><b>Question: How many times can I listen to the audio or take the tour against one payment?</b></p>
                                <p>Answer: Once you have paid, you can take the tour again and again as long as the validity lasts. Typically, most tours have a validity of 90 days and packages a validity of one year. The validity may differ from tour to tour or for a specific promo code</p>

                                <p><b>Question: Who can I approach if I need support?</b></p>
                                <p>Answer: You can reach out to us at  contact@newhoiwebsite.museums22.com. You can give us a call during office (India) hours or text us on WhatsApp at +91-9620534431/ +91-7906681236</p>

                                <p><b>Question: How can I claim a refund?</b></p>
                                <p>Answers: All sales made are final. You are advised to study our app and products before you purchase them. However, If you have purchased our product and our App is  not able to function for a continuous period of 7 days as a result of our fault or failure, you can claim a refund of the  amount that you have paid to us for that specific product</p>

                                <p><b>Question: How can I write a tour for HopOn India?</b></p>
                                <p>Answer: Please contact us at contact@newhoiwebsite.museums22.com to discuss your proposal</p>

                                <p><b>Question: How can I partner or collaborate with HopOn India?</b></p>
                                <p>Answer: We also lease out our technology and content curation expertise. Museums, monuments, forts, palaces, university campuses, travel trade operators, and art galleries may get their own audio guide app and/ or tours made. Please write to us at contact@newhoiwebsite.museums22.com</p>

                                <p><b>Question: What are the privacy policies, terms, and conditions?</b></p>
                                <p>Answer: You can read them at Privacy Policy page</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* faqs section ends here */}

                <FooterComponent />

            </Fragment>
        )
    }
}